import {Children, useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {EffectCoverflow, Controller} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react' // eslint-disable-line
import 'swiper/css' // eslint-disable-line
import 'swiper/css/effect-coverflow' // eslint-disable-line
import reviewText from '../assets/review-text.png'
import media from '../utils/media'
import ContentContainer from './ContentContainer'


const StyledImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 3.75rem;
  ${media.down('mobile')(css`
    left: 50%;
    bottom: 5rem;
    transform: translateX(-10%);
    width: fit-content;
  `)}
`

const StlyedImage = styled.img`
  max-width: 15rem;
  height: auto;
  ${media.down('mobile')(css`
    max-width: 12rem;
  `)}
`

const StyledBulletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 3rem;
  ${media.down('mobile')(css`
    padding: 6rem 0 3rem;
  `)}
`

const StyledBullet = styled.span`
  display: block;
  transition: transform 0.2s ease-in, background 0.2s ease-in;
  margin: 0 0.5rem;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.text};
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  ${({active}) => active && css`
    background: ${({theme}) => theme.colors.primary};
    transform: scale(1.75);
  `}
`

const Slider = ({initialSlide, control, onSlideChange, onSwiper, children, ...props}) => {
  const [activeIndex, setActiveIndex] = useState(initialSlide || 0)

  const handleSlideChange = (e) => {
    setActiveIndex(e.activeIndex)
    if (onSlideChange) onSlideChange(e)
  }

  const handleNavigationClick = (index) => () => {
    control.slideTo(index)
  }

  return (
    <ContentContainer position="relative">
      <Swiper
          slidesPerView={3}
          initialSlide={1}
          centeredSlides
          effect="coverflow"
          watchSlidesVisibility
          slidesPerGroup={1}
          spaceBetween={0}
          centerInsufficientSlides
          controller={{control}}
          onSwiper={onSwiper}
          onSlideChange={handleSlideChange}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 3,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Controller]}
          {...props}
      >
        {Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
      <StyledBulletContainer>
        {Children.map(children, (_child, index) => (
          <StyledBullet active={index === activeIndex} onClick={handleNavigationClick(index)} />
        ))}
      </StyledBulletContainer>
      <StyledImageContainer>
        <StlyedImage src={reviewText} alt="kliknite pre moju recenziu" />
      </StyledImageContainer>
    </ContentContainer>
  )
}

Slider.defaultProps = {
  initialSlide: 1,
}

Slider.propTypes = {
  initialSlide: PropTypes.number,
  control: PropTypes.object,
  onSlideChange: PropTypes.func,
  onSwiper: PropTypes.func,
  children: PropTypes.node,
}

export default Slider
